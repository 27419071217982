export default  {
  "colors": {
    "gray": {
      "50": "#F1EEF7",
      "100": "#D8CEE8",
      "200": "#BFAFD9",
      "300": "#A790CB",
      "400": "#8E71BC",
      "500": "#7552AD",
      "600": "#5D418B",
      "700": "#463168",
      "800": "#2F2145",
      "900": "#171023"
    },
    "red": {
      "50": "#FEE7E9",
      "100": "#FBBBC2",
      "200": "#F98F9A",
      "300": "#F76473",
      "400": "#F5384C",
      "500": "#F20D24",
      "600": "#C20A1D",
      "700": "#910816",
      "800": "#61050E",
      "900": "#300307"
    },
    "orange": {
      "50": "#FFF3E5",
      "100": "#FFDEB8",
      "200": "#FFC98A",
      "300": "#FFB35C",
      "400": "#FF9E2E",
      "500": "#FF8900",
      "600": "#CC6D00",
      "700": "#995200",
      "800": "#663700",
      "900": "#331B00"
    },
    "yellow": {
      "50": "#FFFCE5",
      "100": "#FFF7B8",
      "200": "#FFF18A",
      "300": "#FFEC5C",
      "400": "#FFE62E",
      "500": "#FFE100",
      "600": "#CCB400",
      "700": "#998700",
      "800": "#665A00",
      "900": "#332D00"
    },
    "green": {
      "50": "#E8FCF5",
      "100": "#BFF8E3",
      "200": "#96F3D0",
      "300": "#6CEEBE",
      "400": "#43EAAC",
      "500": "#1AE59A",
      "600": "#15B77B",
      "700": "#0F8A5C",
      "800": "#0A5C3E",
      "900": "#052E1F"
    },
    "teal": {
      "50": "#E9FCF9",
      "100": "#C1F6EF",
      "200": "#98F0E4",
      "300": "#70EADA",
      "400": "#48E5CF",
      "500": "#20DFC5",
      "600": "#1AB29E",
      "700": "#138676",
      "800": "#0D594F",
      "900": "#062D27"
    },
    "blue": {
      "50": "#EAF0FA",
      "100": "#C4D4F2",
      "200": "#9EB9EA",
      "300": "#799EE2",
      "400": "#5382DA",
      "500": "#2D67D2",
      "600": "#2452A8",
      "700": "#1B3E7E",
      "800": "#122954",
      "900": "#09152A"
    },
    "purple": {
      "50": "#F4EAFB",
      "100": "#E1C4F3",
      "200": "#CE9EEB",
      "300": "#BB78E3",
      "400": "#A752DB",
      "500": "#942CD3",
      "600": "#7723A9",
      "700": "#591A7F",
      "800": "#3B1254",
      "900": "#1E092A"
    },
    "pink": {
      "50": "#FDE8F4",
      "100": "#F9BEDF",
      "200": "#F594CB",
      "300": "#F16AB6",
      "400": "#ED40A2",
      "500": "#E9168D",
      "600": "#BB1171",
      "700": "#8C0D55",
      "800": "#5D0938",
      "900": "#2F041C"
    }
  }
}