import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth } from '@clerk/nextjs';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

export const ApolloProviderWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [token, setToken] = useState<string | null>(null);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchToken = async () => {
      const t = await getToken({ template: 'hasura' });
      setToken(t);
    };
    fetchToken().catch(console.error);
  });

  const apolloClient = useMemo(() => {
    const ssrMode = typeof window === 'undefined';
    const authMiddleware = setContext((_, { headers }) => {
      if (!token) return { headers };
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
          'x-hasura-default-role': 'User',
        },
      };
    });

    const httpLink = new HttpLink({
      uri: process.env.NEXT_PUBLIC_API_URL,
    });

    const wsUrl: string = process.env.NEXT_PUBLIC_WS_URL || '';

    const createWsLink = (token: string | null) => {
      return new GraphQLWsLink(
        createClient({
          url: wsUrl,
          lazy: true,
          keepAlive: 10_000,
          connectionParams: async () => {
            return {
              headers: {
                authorization: token ? `Bearer ${token}` : '',
                'x-hasura-default-role': 'User',
              },
            };
          },
        })
      );
    };
    const link = !ssrMode
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          createWsLink(token),
          httpLink
        )
      : httpLink;

    return new ApolloClient({
      // link:
      link: from([authMiddleware, link]),
      cache: new InMemoryCache(),
    });
  }, [token]);

  if (!apolloClient) return null;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
