import { CloseIcon, HamburgerIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { useAuth, useSignIn, useUser } from '@clerk/nextjs';
import { SignOut, UserResource } from '@clerk/types';
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from '@chakra-ui/next-js';
import { useRouter } from 'next/router';
import useAmplitudeContext from 'components/Amplitude/useAmplitudeContext';

const Links = [
  { title: 'Home', route: '' },
  { title: 'Pricing', route: 'pricing' },
];

interface navProps {
  route: string;
  title: string;
}

const NavLink = (props: navProps) => {

  const bgColor = useColorModeValue('green.500', 'green.400');

  if (props.route.startsWith('http')) {
    return (
      <a
        href={props.route}
        target='_blank'
        rel='noopener noreferrer'
        style={{
          textDecoration: 'none',
          backgroundColor: bgColor,
          padding: '0.5rem 1rem',
          borderRadius: '0.375rem',
          fontSize: '1.5em',
          fontWeight: 'bold'
        }}
      >
        {props.title}
      </a>
    );
  }

  return (
    <Link
      href={'/' + props.route}
      id={`route_${props.route}`}
      fontSize={'xl'}
      fontWeight={'Bold'}
      _hover={{ paddingBottom: 1 }}
    >
      {props.title}
    </Link>
  );
};

const UserMenu = (user: UserResource, signOut: SignOut) => {
  return (
    <Box>
      <HStack>
        <Link id='profile_link' href={'https://app.cohezion.ai'}>
          Dashboard
        </Link>
        <Menu>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}
            id='avatar'
          >
            <Avatar src={user.profileImageUrl || ''} />
          </MenuButton>
          <MenuList alignItems={'center'}>
            <br />
            <Center>
              <Avatar size={'2xl'} src={user.profileImageUrl || ''} />
            </Center>
            <br />
            <Center>
              <p>{user.username}</p>
            </Center>
            <MenuItem>
              <Link id='profile_link' href={'/user'}>
                Profile
              </Link>
            </MenuItem>

            {/* <MenuItem>Contributions</MenuItem> */}
            <MenuDivider />
            <MenuItem id='signout' onClick={() => signOut()}>
              Sign out
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Box>
  );
};

export default function NavBar() {
  const { isLoaded, signOut } = useAuth();
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('gray.500', 'gray.900');
  const bgIconColor = useColorModeValue('gray.700', 'gray.600');
  const linkColor = useColorModeValue('white', 'white');
  const router = useRouter();
  const { signIn } = useSignIn();
  // @ts-expect-error types hard
  const { trackAmplitudeEvent } = useAmplitudeContext();

  const Login = () => {
    return (
      <Menu>
        <MenuButton
          as={Button}
          id='signin'
          rounded={'full'}
          onClick={() => {
            router.push({
              pathname: '/sign-in',
              query: { redirect: router.asPath },
            });
          }}
        >
          Sign In
        </MenuButton>
      </Menu>
    );
  };

  let component;
  if (isLoaded && user) {
    component = UserMenu(user, signOut);
  } else component = Login();

  const logoSrc = useColorModeValue(
    '/images/logo_dark.png',
    '/images/COHEZION_LOGOS-03.png'
  );

  const openExternalLink = (url: string) => {
    trackAmplitudeEvent('external_link_clicked', { url })
    window.open(url, '_blank');
  };

  return (
    <>
      <Box px={4}>
        <Container maxW={'8xl'}>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'sm'}
              bgColor={bgIconColor}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack>
              <LinkBox>
                <LinkOverlay href='/'>
                  <Image alt='COHEZION logo' src={logoSrc} width={'200px'} />
                </LinkOverlay>
              </LinkBox>
              <HStack
                as={'nav'}
                spacing={4}
                paddingLeft={10}
                display={{ base: 'none', md: 'flex' }}
              >
                {Links.map((link, index) => (
                  <NavLink
                    key={'l' + index}
                    route={link.route}
                    title={link.title}
                  ></NavLink>
                ))}
                <Menu>
                  <MenuButton variant={'link'} as={Button} fontSize={'xl'} transition='all 0.2s' rightIcon={<IoMdArrowDropdown />}> Products</MenuButton>
                  {/* <Portal> */}
                  <MenuList zIndex={900} fontSize={'xl'}>
                    <MenuItem
                      key={'bug-reporting'}
                      onClick={() => {
                        trackAmplitudeEvent('click nav bug reporting')
                        router.push({
                          pathname: '/product/bug-reporting',
                        });
                      }}
                    >
                      Bug Reporting
                    </MenuItem>
                    <MenuItem
                      key={'feedback'}
                      onClick={() => {
                        trackAmplitudeEvent('click nav feedback')
                        router.push({
                          pathname: '/product/feedback',
                        });
                      }}
                    >
                      Feedback
                    </MenuItem>
                    <MenuItem
                      key={'community'}
                      onClick={() => {
                        trackAmplitudeEvent('click nav community analytics')
                        router.push({
                          pathname: '/product/community-analytics',
                        });
                      }}
                    >
                      Community Analytics
                    </MenuItem>
                  </MenuList>
                  {/* </Portal> */}
                </Menu>
                <Menu>
                  <MenuButton variant={'link'} as={Button} fontSize={'xl'} transition='all 0.2s' rightIcon={<IoMdArrowDropdown />}>Resources</MenuButton>
                  <Portal>
                    <MenuList zIndex={900} fontSize={'xl'}>
                      <MenuItem
                        onClick={() => {
                          openExternalLink(
                            'https://discord.com/blog/how-we-used-discord-to-build-a-dream-community-for-our-game-descenders'
                          );
                        }}
                      >
                        Discord Article: Descenders
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          openExternalLink(
                            'https://discord.com/blog/pushing-the-boundaries-of-discord-sabotage-studios-server-experience'
                          );
                        }}
                      >
                        Discord Article: Sea of Stars
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          openExternalLink('https://blog.cohezion.ai');
                        }}
                      >
                        Blog
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              </HStack>
            </HStack>
            <Flex alignItems={'center'}>
              <Stack
                direction={'row'}
                spacing={4}
              >
                <Box>{component}</Box>
              </Stack>
            </Flex>
          </Flex>

          {isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={4}>
                {Links.map((link, index) => (
                  <NavLink
                    key={'l' + index}
                    route={link.route}
                    title={link.title}
                  ></NavLink>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Container>
      </Box>
    </>
  );
}
